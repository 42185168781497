@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --primary-color: #283c86; */
  --accent-color: #00f6ff;
  --primary-color: #00040f;
  --dimBlue: rgba(9, 151, 124, 0.1);
  --dimWhite: rgba(255, 255, 255, 0.7);
  /* --accent-color: #df42b1; */
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
* {
  font-family: 'Poppins', sans-serif;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/* custom scroll bar */
/* ::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: #000000;
}
::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(
      120deg,
      var(--primary-color) 0%,
      var(--accent-color) 100%
    )
    var(--accent-color);
} */

::-webkit-scrollbar {
  width: .9rem;
}

::-webkit-scrollbar-track{
  background-color: var(--primary-color);
}

::-webkit-scrollbar-thumb{
  background: #00f7ff80;
  border: 4px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.confer-btn {
  position: relative;
  z-index: 1;
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  border: none;
  display: inline-block;
  padding: 0px 25px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: rgb(255, 255, 255);
  border-radius: 23px;
  transition-duration: 500ms;
  overflow: hidden;
}
.confer-btn::before {
  transition-duration: 800ms;
  position: absolute;
  width: 120%;
  height: 120%;
  top: -10%;
  left: -10%;
  content: '';
  background: -webkit-linear-gradient(
      120deg,
      var(--primary-color) 0%,
      var(--accent-color) 100%
    )
    var(--accent-color);
  z-index: -2;
}
.confer-btn:focus,
.confer-btn:hover {
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(15 15 15 / 15%) 0px 2px 40px 8px;
  color: rgb(17, 19, 67) !important;
}
.confer-btn:hover::before {
  left: 110%;
}

.confer-btn.clicked {
  pointer-events: none;
  cursor: progress;
}

.header-area.stick {
  background-color: #000000e5;
}
.header-area.stick .classy-navbar {
  height: 75px;
}
/* Navigation elements */
.classynav ul li {
  display: inline-block;
  clear: both;
  z-index: 10;
}
.classynav ul li a {
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  padding: 0px 20px;
  height: 40px;
  line-height: 40px;
  display: block;
}
.classynav ul li.active a,
.classynav ul li:hover a {
  color: var(--accent-color);
}
/* Dropdown elements */
.classynav .dropdown-item ul li {
  display: block;
  clear: both;
}
.classynav ul .dropdown-item .dropdown a {
  font-size: 14px;
  text-transform: uppercase;
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  color: #111343;
}
.classynav ul.dropdown li:hover > a {
  color: var(--accent-color);
}
.breakpoint-off .classynav ul li:hover > .dropdown {
  opacity: 1;
  visibility: visible;
}

#home .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #000 0, #000 50%);
  opacity: 0.4 !important;
}
.swiper {
  width: 100%;
  height: 100%;
  position: relative;
}

.blend-container img {
  width: 90%;
  margin: 1rem;
  border-radius: 10px;
  margin-left: 2rem;
  margin-bottom: 30px;
}
.bg-gradient-overlay::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
  background: -webkit-linear-gradient(
      360deg,
      #00040ff7 100%,
      #00f7ff77 0%
    )
    #00f7ff77;
  opacity: 0.95;
}
.speaker-section {
  background: url('../images/hero3.jpg');
}
.events-section {
  background: url('../images/hero4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.single-speaker-area .social-info {
  position: absolute;
  top: -180px;
  right: 0px;
  z-index: 22;
  background-color: var(--accent-color);
  padding: 15px;
  border-radius: 0px 10px;
  text-align: center;
  transition-duration: 800ms;
}
.single-speaker-area:hover .social-info,
.single-speaker-area:focus .social-info {
  top: 0px;
}
.bg-gradient-overlay-2::after {
  border-radius: 10px;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.2) 30%,
    rgba(0, 0, 0, 0) 100%
  );
}
.bg-gradient-overlay-3::after {
  border-radius: 10px;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.579) 40%,
    rgba(0, 0, 0, 0) 100%
  );
}

.tab-label {
  transition-property: background-color;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.tab-label:hover {
  background-color: var(--dimBlue);/* Change to desired hover color */
}

.contact-container .form input:invalid[focused='true'] ~ span {
  display: inline-block !important;
}
.single-ticket-pricing-table .ribbon {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}
.single-ticket-pricing-table .ribbon::before,
.single-ticket-pricing-table .ribbon::after {
  position: absolute;
  content: '';
  z-index: -1;
  display: block;
  border: 7px solid var(--primary-color);
  border-top-color: transparent;
  border-left-color: transparent;
}
.single-ticket-pricing-table .ribbon::before {
  top: 0px;
  right: 15px;
}
.single-ticket-pricing-table .ribbon::after {
  bottom: 15px;
  left: 0px;
}
.single-ticket-pricing-table .ribbon span {
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: var(--primary-color);
  border: 2px solid var(--accent-color);
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}

.icon-scroll::before {
  position: absolute;
  z-index: 999;
  content: '';
  width: 6px;
  height: 6px;
  background: #ffffff;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 8px;
  border-radius: 4px;
  -webkit-animation: iconScroll linear 1200ms infinite;
  animation: iconScroll linear 1200ms infinite;
}

.icon-scroll::after {
  content: 'SCROLL DOWN';
  color: white;
  z-index: 999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  width: 150px;
  margin-left: 1rem;
}

@keyframes iconScroll {
  0% {
    top: 8px;
    opacity: 1;
  }

  50% {
    opacity: 0;
    top: 23px;
  }

  100% {
    top: 8px;
    opacity: 0;
  }
}

.navigation p.active {
  background: -webkit-linear-gradient(
      120deg,
      var(--dimBlue) 100%,
      var(--accent-color) 0%
    )
    var(--dimBlue);
  color: white;
  /* box-shadow: 0 1px 5px rgb(0 0 0 / 10%); */
}

/* .navDetails li {
  list-style-image: url(../images/dm1.png);
  list-style-position: outside;
} */

.point{
  list-style-type: disc;
  list-style-position: inside;
}

.para{
  list-style-type: none;
}

.swiper {
  height: max-content !important;
  margin-bottom: 2rem;
}
.swiper-pagination-bullet {
  background: #fff !important;
}
.swiper-pagination-bullet-active {
  background: var(--accent-color) !important;
}

.admin-navigation ul .active {
  background-color: var(--dimBlue);
  border-left: 4px solid var(--accent-color);
}

@media screen and (min-width: 768px) {
  .classynav .dropdown-item .dropdown {
    /* width: 180px; */
    position: absolute;
    background-color: #ffffff;
    /* top: 150%; */
    /* left: 0; */
    z-index: 100;
    height: auto;
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
  }
  .blend-container img {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .classy-navbar .classy-menu {
    background-color: var(--primary-color);
    position: fixed;
    top: 0;
    left: -340px;
    z-index: 9999;
    width: 320px;
    height: 100%;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 0;
    box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .classy-navbar .classy-menu.active {
    left: 0px;
  }
  .classynav {
    padding-top: 60px;
    margin-bottom: 80px;
    display: block;
  }
  .classynav ul {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .classynav ul li {
    display: block;
    position: relative;
    clear: both;
    z-index: 10;
  }
  .classynav > ul > li > a {
    background-color: var(--dimBlue);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    height: 55px;
    line-height: 53px;
    color: white;
  }
  .classynav .dropdown-item > .dropdown {
    display: none;
  }
  .classynav .dropdown-item.active > .dropdown {
    display: block;
  }
  .blend-container img {
    margin-left: 1rem;
  }
}
